import React, { useState, useEffect, useRef } from 'react';
import './Hero.css';
import VideoSrc from '../../assets/video/Cue Spin_2 (1).mp4';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const Hero = () => {
  const [showRandomText, setShowRandomText] = useState(false);
  const [previousRandomAnswer, setPreviousRandomAnswer] = useState('');
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    handleVideoPlay();
    clearRandomText();

    // Set a timeout to display random text after 4 seconds
    setTimeout(() => {
      const randomAnswers = [
        "Jeg har mine tvivl, men en kaffe med Nick kunne ikke skade",
        "Sandsynligvis, men hvorfor stole på et legetøj",
        "Ja! Men ryst eventuelt igen, hvis du er i tvivl",
        "Hvis du bruger legetøj til at træffe beslutninger, vil Nick formentlig være en forbedring.",
      ];

      let randomAnswer = '';
      do {
        randomAnswer = randomAnswers[Math.floor(Math.random() * randomAnswers.length)];
      } while (randomAnswer === previousRandomAnswer);

      setShowRandomText(randomAnswer);
      setPreviousRandomAnswer(randomAnswer);
    }, 8500);
  };

  const handleVideoPlay = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error('Video play error:', error);
      });
    }
  };

  const clearRandomText = () => {
    setShowRandomText(false);
  };

  return (
    <div className="hero-body">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 className="mobile-h1">Er Nick Traulsen den rigtige til at sidde i din bestyrelse? </h1>
        <button className="reveal-button" onClick={handleVideoClick}>
          <FontAwesomeIcon icon={faArrowAltCircleDown} />  Klik for svar
        </button>
      </div>

      <div className="hero-container">
        <div className="landing--vid" onClick={handleVideoClick}>
          <div className="video-container">
            <video
              ref={videoRef}
              src={VideoSrc}
              disableRemotePlayback
              disablePictureInPicture
              playsInline
              muted
              onClick={(e) => e.preventDefault()}
              
            />
          </div>
          {showRandomText && <div className="random-text">{showRandomText}</div>}
        </div>
      </div>

      <div className="custom-hero">
        <a href="#experience">
          <div className="custom-button">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="custom-dot"
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Hero;
