import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './Podcasts.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import Podcast2 from './Podcasts2';

const samplePodcastData = [
  {
    id: 1,
   
    podcastEmbed: (
        <iframe
            title="Podcast 5"
            src="https://open.spotify.com/embed/episode/2jQ4b567Nz8CiEBpv0pg3e?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '20px' }}
        />
    ),
},
{
    id: 2,
    
    podcastEmbed: (
      
        <iframe
            title="Podcast 6"
            src="https://open.spotify.com/embed/episode/2wTA0U6emZkcSZutFXA3aC?utm_source=generator&theme=0"
            width="100%"
            height="155"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '70px' }}
        />
    ),
},
    {
        
        id: 3,
       
        podcastEmbed: (
            <iframe
            title="Podcast 7"
            src="https://open.spotify.com/embed/episode/61DeUFwXJWOMcg47Xn5qHM?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '250px' ,  }}
        />
        ),
    },
   
];

function Podcasts() {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
      
       
        podcast: {
            cursor: 'pointer',
          },
       
    }));

    const classes = useStyles();

    return (
        <div className="podcast" id="podcast" style={{ backgroundColor: theme.secondary }}>
     <div className="podcast-Heading">
      <h2 style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Tanker om kultur</h2>        
      </div>
      <div className="podcast--body">
      
        <div className="podcast--bodyContainer">
          
          {samplePodcastData.map((podcast, index) => (
             (index % 2 === 0) && (
              <div className="podcast-container" key={`container-${podcast.id}`}>
                {samplePodcastData.slice(index, index + 2).map((podcast) => (
                  <div key={podcast.id} className={`single-blog ${classes.podcast}`} style={{ cursor: 'pointer' }}>
                    {podcast.podcastEmbed}
                   
                  </div>
                ))}
              </div>
            )
          ))}
        </div>
       
      </div>
    </div>
    );
}

export default Podcasts;
