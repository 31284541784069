import React, { useContext, useState } from 'react';

import { ThemeContext } from '../../contexts/ThemeContext';

import './Experience.css';

import { experienceData } from '../../data/experienceData';
import ExperienceCard from './ExperienceCard';

function Experience() {
    const { theme } = useContext(ThemeContext);

    const [selectedCategories, setSelectedCategories] = useState([
        'Medstifter/Founder',
        'Investering',
        'Bestyrelse',
    ]);




    // Function to toggle a category on or off
    const toggleCategory = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    return (
        <div className="experience" id="experience" style={{ backgroundColor: theme.secondary }}>
                 <div className="experience-body">
                    <h1 style={{ color: theme.primary , fontSize:'34px'}}>Tidslinje og erfaring</h1>
                    <div className='experience-filter'>
                <label className='checkbox-text' style={{
        color: theme.primary,
        
                }}>
        <input
            type="checkbox"
            value="Medstifter/Founder"
            checked={selectedCategories.includes('Medstifter/Founder')}
            onChange={() => toggleCategory('Medstifter/Founder')}
            className='checkbox-style'
            
        />
        <span>Medstifter/Founder</span>
    </label>
    
    <label className='checkbox-text' style={{
        color: theme.primary,
    }}>
        <input
            type="checkbox"
            value="Investering"
            checked={selectedCategories.includes('Investering')}
            onChange={() => toggleCategory('Investering')}
            className='checkbox-style'
            
        />
        <span>Investering</span>
    </label>

    <label className='checkbox-text' style={{color:theme.primary}}>
        <input
            type="checkbox"
            value="Bestyrelse"
            checked={selectedCategories.includes('Bestyrelse')}
            onChange={() => toggleCategory('Bestyrelse')}
            className='checkbox-style'
        />
        <span>Bestyrelse</span>
    </label>
</div>

                    {experienceData.map((exp) =>
                        selectedCategories.includes(exp.category) ? (
                            <ExperienceCard
                                key={exp.id}
                                id={exp.id}
                                jobtitle={exp.jobtitle}
                                startYear={exp.startYear}
                                image={exp.image}
                            />
                        ) : null
                    )}
                </div>
            </div>
        
    );
}

export default Experience;
