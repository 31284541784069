import React from 'react';
import { Helmet } from 'react-helmet';
import { Blog, Experience, Contacts, Hero } from '../../components';
import { headerData } from '../../data/headerData';


function Main() {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   // Simulating some async operation
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     document.body.style.cursor = 'default';
  //     window.scrollTo(0, 0);
  //   }, 3500);
  // }, []);

  return (
    <>
      
        <div>
          <Helmet>
            <title>{headerData.name} - Porfolio</title>
          </Helmet>

          <Contacts />
          <Hero />
          <Experience />
          <Blog />
         
        </div>
     
    </>
  );
}

export default Main;
