import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './Podcasts2.css';
import { ThemeContext } from '../../contexts/ThemeContext';

const samplePodcastData = [
  {
    id: 1,
    podcastEmbed: (
        <iframe
            title="Podcast 5"
            src="https://open.spotify.com/embed/episode/5e8YYKAUfLyyOoiyNBuwhh?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '20px' }}
        />
    ),
},
{
    id: 2,
    
    podcastEmbed: (
      
        <iframe
            title="Podcast 6"
            src="https://open.spotify.com/embed/episode/2BQRODUUF6YCLyyT2EPRnX?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '20px' }}
        />
    ),
},
    
    {
        
        id: 3,
       
        podcastEmbed: (
            <iframe
            title="Podcast 7"
            src="https://open.spotify.com/embed/episode/2AeuS2Gysnpiq9EMyqU4Zh?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '20px' ,  }}
        />
        ),
    },
    {
        
        id: 4,
       
        podcastEmbed: (
         
            <iframe
            title="Podcast 7"
            src=" https://open.spotify.com/embed/episode/2HudLIn0PtgaAeAW4yd5le/video?utm_source=generator&theme=0"
            width="100%"
            height="155"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '70px' ,  }}
        />
        ),
    }, {
     
        id: 5,
        podcastEmbed: (
            <iframe
            title="Podcast 7"
            src=" https://open.spotify.com/embed/episode/1mX59EMz5PfoOnXutwVCLu?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', marginBottom: '250px' ,  }}
        />
        ),
    },
   
];

function Podcast2() {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
      
       
        podcast: {
            cursor: 'pointer',
          },
       
    }));

    const classes = useStyles();

    return (
        <div className="podcast2" id="podcast" style={{ backgroundColor: theme.secondary }}>
     <div className="podcast2-Heading">
      <h2 style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Tanker om marketing</h2>        
      </div>
      <div className="podcast2--body">
      
        <div className="podcast2--bodyContainer">
          
          {samplePodcastData.map((podcast, index) => (
             (index % 3 === 0) && (
              <div className="podcast2-container" key={`container-${podcast.id}`}>
                {samplePodcastData.slice(index, index + 3).map((podcast) => (
                  <div key={podcast.id} className={`single-blog ${classes.podcast}`} style={{ cursor: 'pointer' }}>
                    {podcast.podcastEmbed}
                   
                  </div>
                ))}
              </div>
            )
          ))}
        </div>
      </div>
    </div>
    );
}

export default Podcast2;
