import resume from '../assets/pdf/resume.pdf';
import OwnerImage from '../assets/png/Owner.jpg'

export const headerData = {
    name: 'Nicktraulsen',
    title: "Skal Nick i din bestyrrelse?",
    desciption:"Syv års urokkelig engagement som bestyrelsesmedlem i Danmark - en rejse præget af dedikation, lederskab og stræben efter excellence. Sammen bygger vi arv, der inspirerer fremtiden. ",
    image: 'https://i.imgur.com/QXCGi1q.jpg',
    resumePdf: resume
}
