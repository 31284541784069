import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';

import { ThemeContext } from '../../contexts/ThemeContext';

import './Experience.css'

function ExperienceCard({ id, jobtitle, startYear, image }) {
    const { theme } = useContext(ThemeContext);
  
    // Define different styles based on the id
    let cardStyle = {
      backgroundColor: theme.primary30,
      ":hover": {
        backgroundColor: theme.primary50,
      },
    };

    let imgStyles= {
      marginBottom:"0px",
    };
  
    let jobTitleStyle = {
    
      paddingTop: "5px",
      
      // Default padding for job titles
      // Add any other default styles for job titles
    };
    let jobTitleBottom = {
    
      paddingBottom: "5px",
      
      // Default padding for job titles
      // Add any other default styles for job titles
    };

   
  
    switch (id) {
      case 1:
        jobTitleStyle.paddingTop = "12px";
        
        break;
        case 2:
          jobTitleStyle.paddingTop= "10px";
          break;
      case 3:
        jobTitleStyle.paddingTop= "12px";
        break;
        case 4:
          jobTitleStyle.paddingTop= "10px";
          break;
          case 5:
          jobTitleStyle.paddingTop= "11px";
          break;
          case 7:
          jobTitleStyle.paddingTop= "13px";
          break;
          case 8:
          jobTitleStyle.paddingTop= "14px";
          break;

        case 6:
            jobTitleStyle.paddingTop="12px";
            break;
        case 10:
            jobTitleStyle.paddingTop="2px";
            break;
            case 9:
              jobTitleStyle.paddingTop="12px";
              break;

            case 13:
                jobTitleStyle.paddingTop="13px";
                break;

                case 14:
                    jobTitleStyle.paddingTop="13px";
                    break;
                    case 10:
                        jobTitleStyle.paddingTop="9px"
                        break;
                        case 12:
                            jobTitleStyle.paddingTop="13px";
                            break;
                            case 11:
                            jobTitleStyle.paddingTop="13px";
                            break;
                            case 15: jobTitleStyle.paddingTop="13px";
                            break;
                            case 16: jobTitleStyle.paddingTop="13px";
                            break;
                            case 17: jobTitleStyle.paddingTop="17px";
                            break;


      default:
    }

    if (window.innerWidth <= 600) {
      switch (id) {
        case 1:
          jobTitleStyle.paddingTop = "13px"; // Adjust padding for id 1 on mobile devices
          // Add any other styles for id 1 on mobile devices
          break;
          case 2:
            jobTitleStyle.paddingTop = "2px"; // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;

            case 4:
            jobTitleStyle.paddingTop = "5px"; // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;

            case 5:
            jobTitleStyle.paddingTop = "6px"; // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;

            case 7:
            jobTitleStyle.paddingTop = "6px"; // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;

            case 8:
            jobTitleStyle.paddingTop = "2px"; 
            imgStyles.marginBottom="8px"
           // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;
            case 9:
              jobTitleStyle.paddingTop = "0px";
              imgStyles.marginBottom="8px"
              break;

            case 11:
            jobTitleStyle.paddingTop = "6px"; // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;

            case 12:
            jobTitleStyle.paddingTop = "6px"; // Adjust padding for id 1 on mobile devices
            // Add any other styles for id 1 on mobile devices
            break;
            
        // Add more cases for other ids as needed
        default:
          // Add default styles for other ids on mobile devices
      }
    }

    if (window.innerWidth <= 440) {
      switch (id) {
        case 1:
          jobTitleStyle.paddingTop = "6px"; // Adjust padding for id 1 on mobile devices
          // Add any other styles for id 1 on mobile devices
          break;
          case 2:
          jobTitleStyle.paddingTop = "6px"; // Adjust padding for id 1 on mobile devices
          // Add any other styles for id 1 on mobile devices
          break;
        case 7:
          jobTitleStyle.paddingTop = "5px"; 
          // Add any other styles for id 3 on mobile devices
          break;
          case 9:
          jobTitleStyle.paddingTop = "5px"; 
          // Add any other styles for id 3 on mobile devices
          break;
          case 14:
          jobTitleStyle.paddingTop = "-15px"; 
          // Add any other styles for id 3 on mobile devices
          break;
          case 15:
          jobTitleBottom.paddingBottom = "5px"; 
          // Add any other styles for id 3 on mobile devices
          break;
          case 16: jobTitleStyle.paddingTop="14px";
                            break;
                            case 17: jobTitleStyle.paddingTop="15px";
                            break;
        // Add more cases for other ids as needed
        default:
          // Add default styles for other ids on mobile devices
      }
    }
  
    
    return (
      <Fade bottom>
        <div key={id} className="experience-card" style={{ ...cardStyle }}>
          <div className="expcard-img" >
            <img src={image} alt="Logo" style={{...imgStyles}} />
          </div>
          <div className="experience-details">
            <h2 style={{ color: theme.tertiary, ...jobTitleStyle }}>
              {startYear}: {jobtitle}
            </h2>
          </div>
        </div>
      </Fade>
    );
  }
  
  export default ExperienceCard;
  
  
  
  
  
  
  
  
  
  
  
