import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FiPhone, FiMail, FiCoffee } from 'react-icons/fi';
import { ThemeContext } from '../../contexts/ThemeContext';
import { contactsData } from '../../data/contactsData';
import emailjs from '@emailjs/browser'; // Import emailjs-com library

import './Contacts.css';

function Contacts() {
    const { theme } = useContext(ThemeContext);

    const [userEmail, setUserEmail] = useState('');

    const useStyles = makeStyles((t) => ({
        input: {
           
            backgroundColor: 'white',
            width:'20%',
            
            color: 'black',
            fontFamily: 'Roboto',
            fontWeight: 500,
           
        },
        
        submitBtn: {
            backgroundColor: 'white',
            color: 'black',
            fontFamily:'Roboto',
            transition: '250ms ease-in-out',
            '&:hover': {
                transform: 'scale(1.08)',
                color: 'white',
                backgroundColor: '#441052',
            },
        },
    }));

    const classes = useStyles();

    const sendEmail = () => {
        emailjs
            .send(
                'service_mhbpttb',
                'template_mvkntsn',
                {
                    to_email: 'nt@themarketingguy.dk',
                    user_email: userEmail,
                    from_name: userEmail, // Use the user's input email as the sender's name
                    message: 'Your message goes here', // Replace with the actual message
                },
                'iex-ixdKbW2LZUVFs'
            )
            .then(
                function (response) {
                    console.log('Email sent successfully:', response);
                },
                function (error) {
                    console.error('Email sending failed:', error);
                }
            );
    };
    
    

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    return (
        <div className='top-bar' style={{ backgroundColor: '#3f0d51' }}>
            <div className='top-bar-content'>
                <div className='coffee-flex'>
                    <p className='coffee-text' style={{ color: 'white' }}>
                        Skal vi drikke en kop kaffe? <FiCoffee style={{ color: 'white' }} />
                    </p>
                </div>
                
                <input
                    type='email'
                    placeholder='Enter your email...'
                    className="contact-input"
                    value={userEmail}
                    onChange={handleEmailChange}
                />
                <button className="contact-button" onClick={sendEmail}>
                    Ja tak til kaffe
                </button>
                <div className='contact-info'>
                    <a
                        href={`mailto:${contactsData.email}`}
                        className='contact-link'
                        style={{ color: 'white', fontFamily: 'Roboto', fontWeight: 'bold' }}
                    >
                        <FiMail />
                        {contactsData.email}
                    </a>
                    <a
                        href={`tel:${contactsData.phone}`}
                        className='contact-link'
                        style={{ color: 'white', fontFamily: 'Roboto', fontWeight: 'bold' }}
                    >
                        <FiPhone />
                        {contactsData.phone}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
