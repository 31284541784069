import one from '../assets/png/20200624110606-523ee2bfeee93f0204aa3e0830aeb6ae.avif';
import two from '../assets/png/Soundboks.jpg';
import three from '../assets/png/unnamed.png';
import four from '../assets/png/Elite.png';
import five from '../assets/png/Havafiesta.jpg';
import seven from '../assets/png/Bilhuset Laursen.jpg';
import eight from '../assets/png/Atlas Partners.png';
import nine from '../assets/png/Bilhuset Laursen.jpg';
import ten from '../assets/png/Keystones.png'
import eleven from '../assets/png/Healper.png';
import twelve from '../assets/png/PV.png';
import thirteen from '../assets/png/AMTech.png';
import fourteen from '../assets/png/download.png';
import fifteen from '../assets/png/fifteen.png';
import sixteen from '../assets/png/sixteen.jpg';
import seventeen from '../assets/png/seventeen.jpg';

export const experienceData = [
    {
        id: 1,
        jobtitle: 'Medstifter af The Marketing Guy - Marketingbureau',
        startYear: '2016',
        image: one,
        category: 'Medstifter/Founder',
    },
    {
        id: 2,
        jobtitle: 'Hjælper SOUNDBOKS med annoncering og opstart. Siger nej til at investere. Dumt 🤦',
        startYear: '2016',
        image: two,
        category: 'Investering',
    },
    {
        id: 3,
        jobtitle: 'Investering i Zetland',
        startYear: '2018',
        image: three,
        category: 'Investering',
    },
    {
        id: 4,
        jobtitle: 'Køb af Cocktail- og kaffebar i Aarhus, lige inden corona. Dumt 🤦',
        startYear: '2019',
        image: twelve,
        category: 'Investering',
    },
    {
        id: 5,
        jobtitle: 'Investering i og advisoryboard for Elite Advertising - LinkedIn Bureau',
        startYear: '2020',
        image: four,
        category: 'Investering' || 'Bestyrelse',
    },
    {
        id: 6,
        jobtitle: 'Investering i Havafiesta - Iskaffe',
        startYear: '2020',
        image: five,
        category: 'Investering',
    },
    {
        id: 7,
        jobtitle: 'Investering i Outletland (Senere en del af Reshopper)',
        startYear: '2020',
        image: sixteen,
        category: 'Investering',
    },
    {
        id: 8,
        company: '',
        jobtitle: 'Opkøb, og turnaround, af et 30-årigt hardware firma - Resultat 2 år efter overtagelse på 2.9m.',
        startYear: '2021',
        image: thirteen,
        category: 'Investering' || 'Medstifter/Founder',
    },
    {
        id: 9,
        company: '',
        jobtitle: 'Bar i Aarhus afleverer ‘21 regnskab og laver 800k i overskud. Rart 🥳',
        startYear: '2022',
        image: twelve,
        category: 'Investering',
    },
    {
        id: 10,
        jobtitle: 'Medstifter af Atlas Partners, godkendt af Nasdaq som Certified Advisor - Fundraising og børsnoteringer på First North',
        startYear: '2022',
        image: eight,
        category: 'Medstifter/Founder',
    },
    {
        id: 11,
        jobtitle: ' Investering i Healper - Psykologplatform. Sundt 🩹',
        startYear: '2022',
        image: eleven,
        category: 'Investering',
    },
    {
        id: 12,
        jobtitle:'Køb af Edaptio - Læringsplatform for skoler og virksomheder',
        startYear: '2022',
        image:  fourteen,
        category:'Medstifter/Founder' || 'Investering'
    },
    {
        id: 13,
        jobtitle: 'Outletland merger med Reshopper',
        startYear: '2022',
        image: seventeen,
        category: 'Bestyrelse',
    },
    {
        id: 14,
        jobtitle: 'Køber secondaries i SOUNDBOKS og dulmer derved ærgrelsen fra 2016. Smart 🥳 ',
        startYear: '2023',
        image: two,
        category: 'Investering',
    },
    {
        id: 15,
        jobtitle: 'Bestyrelsesseminar hos Keystones',
        startYear: '2023',
        image: ten,
        category: 'Bestyrelse',
    },
    {
        id: 16,
        jobtitle: 'Bestyrelsesmedlem af Bilhuset Laursen',
        startYear: '2023',
        image: nine,
        category: 'Bestyrelse',
    },
    {
        id: 17,
        jobtitle: 'Bestyrelsesmedlem af Mesterflyt',
        startYear: '2024',
        image: fifteen,
        category: 'Bestyrelse',
    },
   
    
   
];
