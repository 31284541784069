import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './Blog.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import Podcasts from '../podcasts/Podcasts';
import Podcast2 from '../podcasts/Podcasts2';
const sampleBlogData = [
    
    {
        id: 1,
        podcastEmbed: (
            <iframe
                title="Podcast 5"
                src="https://open.spotify.com/embed/episode/5XCiTtVrB3L7uHLL9dbAI8?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px', marginBottom: '20px' }}
            />
        ),
    },
    {
        id: 2,
        podcastEmbed: (
            <iframe
                title="Podcast 6"
                src="https://open.spotify.com/embed/episode/0HYLrxVbeWcf31dh6jQzSF?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px', marginBottom: '20px' }}
            />
        ),
    },
    {
        id: 3,
        podcastEmbed: (
            <iframe
                title="Podcast 5"
                src="https://open.spotify.com/embed/episode/6wQ6E0TkoacRVsWAGY1D2G?utm_source=generator&theme=0&t=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px', marginBottom: '20px' }}
            />
        ),
    },
    {
        id: 4,
        podcastEmbed: (
            <iframe
                title="Podcast 6"
                src="https://open.spotify.com/embed/episode/7mPq0kt5yLQe7jYvYRkuTW?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px', marginBottom: '20px' }}
            />
        ),
    },
];

function Blog() {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        blogTitle: {
            color: theme.primary,
            fontFamily: 'Arial, sans-serif',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        blogDescription: {
            color: '#666',
            fontFamily: 'Verdana, sans-serif',
            fontSize: '14px',
            marginBottom: '20px',
            position: 'relative',
            transition: 'color 0.3s ease',
            '&:hover': {
                color: theme.primary, // Change to your desired hover color
            },
        },
        podcast: {
            cursor: 'pointer',
          },
        // podcastContainer: {
        //     cursor: 'pointer',
        //   },
    }));

    const classes = useStyles();

    return (
        <div className="blog" id="blog" style={{ backgroundColor: theme.secondary }}>
            <div>
      <div className="blog--header">
        <h1 style={{ color: theme.primary }}>PODCASTS</h1>
        <p style={{ color: 'white', width: '50%', marginTop: '-20px', paddingBottom: '20px', fontSize: '18px', textDecoration: 'none', fontFamily: 'Roboto' }}>
          Alle har efterhånden været med i en podcast, men hvis du vil have et indblik i,
          hvordan jeg tænker, så er disse podcasts nok den bedste måde at lære mig at kende og forstå mine holdninger.
        </p>
        
      </div>
      <div className="blog-Heading">
      <h2 style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Investering og vækst</h2>        
      </div>
      </div>
      <div className="blog--body">
        <div className="blog--bodyContainer">
         
          {sampleBlogData.map((blog, index) => (
            (index % 2 === 0) && (
              <div className="podcast-container" key={`container-${blog.id}`}>
                {sampleBlogData.slice(index, index + 2).map((podcast) => (
                  <div key={podcast.id} className={`single-blog ${classes.podcast}`} style={{ cursor: 'pointer' }}>
                    {podcast.podcastEmbed}
                    {/* <h2 className={classes.blogTitle}>{podcast.title}</h2>
                    <p className={classes.blogDescription}>{podcast.description}</p> */}
                  </div>
                ))}
                
              </div>
            )
          ))}
         
        </div>
        <Podcasts />
      <Podcast2 />
      </div>
    </div>
    );
}

export default Blog;
